(() => {
  // handling the menu open and close in the mobile view
  const handleMobileMenu = () => {
    const menuOpenButton = document.querySelector(
      '.header__menu-open-toggle'
    ) as HTMLButtonElement;

    const mediaQuery = window.matchMedia('(min-width: 992px)');

    window.Bus.on('emu-button:click', ({ id }) => {
      // when close button is clicked, click the open button to toggle the dropdown
      if (id === 'header__menu-close-toggle') {
        menuOpenButton?.click();
      }
    });

    // to close opened header menu when user resizes to desktop view
    mediaQuery.addEventListener('change', event => {
      if (event.matches && menuOpenButton.classList.contains('js-toggle-on')) {
        menuOpenButton?.click();
      }
    });
  };

  // handling the language toggling
  const handleLangToggle = () => {
    const langElements = document.querySelectorAll(
      '.header__toggles a'
    ) as NodeListOf<HTMLAnchorElement>;
    let currentURL = window.location.href;
    let newURL;

    langElements.forEach(element => {
      element.addEventListener('click', e => {
        e.preventDefault();
        let langPathEn = `${window.location.host}/en`;
        let langPathFr = `${window.location.host}/fr`;

        if (
          currentURL.includes(langPathEn) ||
          currentURL.includes(langPathFr)
        ) {
          //replacing /en/ or /en to /fr/ or /fr as home page using /en and /fr
          element.getAttribute('href')?.includes('/fr')
            ? (newURL = currentURL.replace(/\/en(\/|$)/, '/fr$1'))
            : (newURL = currentURL.replace(/\/fr(\/|$)/, '/en$1'));
        }

        if (newURL.includes('undefined')) {
          return;
        }

        window.location.href = newURL;
      });
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      handleMobileMenu();
      handleLangToggle();
    });
  } else {
    handleMobileMenu();
    handleLangToggle();
  }
})();
