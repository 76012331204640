(() => {
  // when modal is closed, if there is an iframe, resets the URL so that any videos that are playing will be stopped
  const handleVideoModal = () => {
    const $videoModals = document.querySelectorAll('.video-modal');
    $videoModals.forEach($el => {
      const modalId = $el.getAttribute('data-id');
      const $iframes = $el.querySelectorAll('iframe');

      if (modalId && $iframes) {
        window.Bus?.on('emu-modal:close', ({ id }) => {
          if (id === modalId) {
            $iframes.forEach($iframe => {
              // resetting the URL for the iframe so that the video will pause
              $iframe.src = $iframe.src;
            });
          }
        });
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleVideoModal);
  } else {
    handleVideoModal();
  }
})();
