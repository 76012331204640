// Stylesheets
import './main.scss';

// Import favicon here.
import './resources/images/favicon.png';

// Imports all component based scss files.
import './components/**/*.scss';

// Imports all component based ts files.
import './components/**/*.ts';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

const handleTabSelection = () => {
  const activeTabHash = window.location.hash?.substring(1);
  if (!activeTabHash) return;

  const tabPanelId = document
    .getElementById(activeTabHash)
    ?.closest('.aaaem-tabs__tabpanel')
    ?.getAttribute('id');

  let elementToScroll: Element | undefined;

  if (tabPanelId) {
    const anchorElement = document.querySelector(
      `a[href="#${tabPanelId}"]`
    )! as HTMLAnchorElement;

    if (!anchorElement) return;

    anchorElement.click();

    elementToScroll = anchorElement;
  } else {
    const targetElement = document.querySelector(window.location.hash);
    if (targetElement) elementToScroll = targetElement;
  }

  if (elementToScroll) {
    setTimeout(() => {
      window.scrollTo({
        top: elementToScroll.getBoundingClientRect().top + window.scrollY - 100,
        behavior: 'smooth',
      });
    }, 400);
  }
};

(() => {
  const init = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      scrollbarWidth + 'px'
    );

    handleTabSelection();

    if (window.Bus) {
      // listen to events here
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
