(() => {
  const handleSymbol = () => {
    const accordions = document.querySelectorAll(
      '.accordion-with-sup-in-titles'
    );
    const specialChars = ['®', 'ᴹᴰ'];

    accordions?.forEach(accordion => {
      const accordionTitle = accordion?.querySelectorAll(
        '.aaaem-accordion__title'
      );
      accordionTitle?.forEach(title => {
        const titleHTML = title.innerHTML;
        specialChars.forEach(char => {
          try {
            title.innerHTML = title.innerHTML
              .split(char)
              .join(`<sup>${char}</sup>`);

            const $sups = title.querySelectorAll('sup');
            $sups.forEach($el => {
              if ($el.textContent?.includes('ᴹᴰ')) {
                $el.classList.add('sup-md');
              }
            });
          } catch (e) {
            title.innerHTML = titleHTML;
          }
        });
      });
    });
  };

  // on resize and on expand of accordion, making sure that the height of the accordion content is accurate
  const handleAccordion = () => {
    handleSymbol();
    const accordions = document.querySelectorAll('.js-badger-accordion');
    if (accordions?.length) {
      let winWidth = window.innerWidth;
      const badgerInstances = window._badgerInstances;
      const badgerKeys = badgerInstances && Object.keys(badgerInstances);
      if (badgerKeys?.length) {
        const resizeBadgerHeights = () => {
          badgerKeys.forEach(key => {
            window._badgerInstances[key].calculateAllPanelsHeight?.();
          });
        };

        // making sure the height of the accordion content is accurate on load.
        window.addEventListener('load', () => {
          resizeBadgerHeights();
        });

        // making sure the height of the accordion content is accurate on resize.
        window.addEventListener('resize', () => {
          const curWidth = window.innerWidth;
          if (winWidth !== curWidth) {
            winWidth = curWidth;
            resizeBadgerHeights();
          }
        });
      }
    }

    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleAccordion);
  } else {
    handleAccordion();
  }
})();
